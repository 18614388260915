import { Component, OnInit, DestroyRef, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSelectChange } from '@angular/material/select';
import { combineLatest, BehaviorSubject, Observable, from } from 'rxjs';
import { Store } from '@ngrx/store';
import { skipWhile, filter, switchMap, tap, map } from 'rxjs/operators';
import { NgForage } from 'ngforage';
import { Apollo } from 'apollo-angular';
import { OpenTeamSelectorAction } from 'app/redux/actions/dashboard';
import { OpenLogOutDialogAction } from 'app/redux/actions/loginuser';
import { GetUserSettingsAction } from 'app/redux/actions/settings';
import { ChangeCurrentRoleAction, GoToDashboardAction } from 'app/redux/actions/dashboard';
import { NotificationReceived } from 'app/subscriptions/NotificationReceived';
import { CURRENT_ROLE } from 'app/shared/consts';
import { ResponsiveService } from '../services/responsive.service';
import { Notification } from '../models/notification';
import { AddNotificationAction, GetNotificationsAction } from '../redux/actions/notifications';
import { NotificationStatus, ScreenSize, UserRole } from '../shared/enums';
import { getNotificationsModel, State, getUserProfileRoles, getDashboardRole } from '../redux/reducers';
import { UserCompanyRole } from '@cartwheel/web-components';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {
  selectedNotification: Notification = new Notification();
  isMobile$: Observable<boolean>;
  isLgScreen$: Observable<boolean>;
  notifications$: Observable<Notification[]>;
  unreadNotifications$ = new BehaviorSubject<Notification[]>([]);
  currentRoute$ = new BehaviorSubject<String>(null);
  roles: UserCompanyRole[];
  currentRole$: Observable<UserCompanyRole>;
  UserRole = UserRole;
  menuItems: {label: string, routerlink: string, userRoles: UserRole[], data_cy?: string}[] = [
    {
      label: 'Dashboard',
      routerlink: '/Home',
      userRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager, UserRole.User]
    },
    {
      label: 'Clients',
      routerlink: '/Home/Clients',
      userRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager, UserRole.User],
      data_cy: 'clientHeaderLink'
    },
    {
      label: 'Timesheets',
      routerlink: '/Home/Timesheets',
      userRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager, UserRole.User]
    },
    {
      label: 'Projects',
      routerlink: '/Home/Projects',
      userRoles: [UserRole.User],
      data_cy: 'projectHeaderLink'
    },
    {
      label: 'Users',
      routerlink: '/Home/Users',
      userRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager]
    },
    {
      label: 'Invoices',
      routerlink: '/Home/Invoices',
      userRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager]
    },
    // {
    //   label: 'Reports',
    //   routerlink: '/Home/Reports',
    //   userRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager]
    // },
    {
      label: 'Settings',
      routerlink: '/Home/Settings',
      userRoles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager, UserRole.User],
      data_cy: 'settings-link'
    }
  ];

  private router = inject(Router);
  private store = inject(Store<State>);
  private responsiveService = inject(ResponsiveService);
  private apollo = inject(Apollo);
  private lStorage = inject(NgForage);
  private destoryRef = inject(DestroyRef);

  ngOnInit(): void {
    this.store.dispatch(new GetNotificationsAction());
    this.notifications$ = this.store.select(getNotificationsModel);
    this.currentRole$ = this.store.select(getDashboardRole).pipe(skipWhile(role => !role));

    this.router.events
      .pipe(
        takeUntilDestroyed(this.destoryRef),
        filter((s: NavigationEnd) => s instanceof NavigationEnd)
      )
      .subscribe(s => this.currentRoute$.next(s.url.split('?')[0]));

    this.currentRole$
      .pipe(
        takeUntilDestroyed(this.destoryRef),
        skipWhile(r => !r || !r.userId || this.router.url.toLocaleLowerCase().indexOf('login') > -1),
        switchMap(role => new NotificationReceived(this.apollo, role?.userId).subscribe())
      )
      .subscribe(sr => this.store.dispatch(new AddNotificationAction(new Notification(sr.data.notificationReceived))))

    this.currentRole$
      .pipe(
        takeUntilDestroyed(this.destoryRef),
        filter(role => !!role),
        tap(() => this.store.dispatch(new GetUserSettingsAction()))
      )
      .subscribe(s => this.changeMenuItemsByCurrentRole(s.userRole));

    combineLatest([
      this.store.select(getUserProfileRoles).pipe(skipWhile(roles => !roles)),
      this.currentRole$
    ])
      .pipe(
        takeUntilDestroyed(this.destoryRef),
        filter((r) => !!r)
      )
      .subscribe(([roles, currentRole]) => {
        this.roles = roles
          .filter(r => r.userRole !== UserRole.User || (r.userRole === UserRole.User && r.id === null))
          .filter(r => !currentRole || r.companyName !== currentRole.companyName);
      });

      this.isMobile$ = this.responsiveService.getMobileStatus();

    this.isLgScreen$ = this.responsiveService.getScreenSize()
      .pipe(
        map(s => s === ScreenSize.Large)
      );

    this.currentRoute$.next(this.router.url);

    this.notifications$
      .pipe(takeUntilDestroyed(this.destoryRef))
      .subscribe(notifications => {
        this.unreadNotifications$.next(notifications.filter(n => n.status === NotificationStatus.Unread));
      })
  }

  logout(): void {
    this.store.dispatch(new OpenLogOutDialogAction());
  }

  changeRouter(event: MatSelectChange): void {
    this.router.navigate([event.value]);
  }

  openTeamSelectorDialog(): void {
    this.store.dispatch(new OpenTeamSelectorAction());
  }

  readNotification(value: Notification): void {
    this.selectedNotification = value;
  }

  changeRole (role: UserCompanyRole): void {
    this.store.dispatch(new ChangeCurrentRoleAction(role));
    from(this.lStorage.setItem(CURRENT_ROLE, JSON.stringify(role)))
      .pipe(takeUntilDestroyed(this.destoryRef))
      .subscribe(() => this.store.dispatch(new GoToDashboardAction()));
  }

  
  private changeMenuItemsByCurrentRole(currentRole: UserRole): void {
    this.menuItems.forEach(t => {
      if (currentRole === UserRole.User && t.routerlink.includes('/Team')) {
        t.routerlink = t.routerlink === '/Team'
          ? '/Home'
          : t.routerlink.replace('/Team', '/Home');
      } else if (currentRole !== UserRole.User && !t.routerlink.includes('/Team')) {
        t.routerlink = t.routerlink === '/Home'
          ? '/Team'
          : t.routerlink.replace('/Home', '/Team');
      }
    });
  }
}
